<template>
  <div>
    <Header :title="'交易规则'" :back="true"/>
    <div class="buy">
      <div class="tab-box2">
        <el-tabs v-model="activeName2">
          <el-tab-pane label="买入规则" name="ru"></el-tab-pane>
          <el-tab-pane label="转出规则" name="chu"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="tab-content-box">
      <div class="three-box" v-show="activeName2=='ru'">
        <div class="title title_line">交易规则</div>
        <div class="flex-box rule box-items">
          <div class="rule-left">
            <div class="rule-title">预约</div>
            <div class="line-left"></div>
            <div class="rule-border"></div>
            <div class="rule-sub-title">今日</div>
            <div class="rule-sub-title">预约买入</div>
          </div>
          <div class="rule-middle">
            <div class="rule-title">亚洲分期-{{month}}月</div>
            <div class="line-middle"></div>
            <div class="rule-border"></div>
            <div class="rule-sub-title">{{interestDay()}}</div>
            <div class="rule-sub-title">成交计息</div>
          </div>
          <div class="rule-right">
             <div class="rule-title">续投/转出</div>
            <div class="line-right"></div>
            <div class="rule-border"></div>
            <div class="rule-sub-title">{{addDays(days)}}</div>
            <div class="rule-sub-title">到期</div>
          </div>
        </div>
        <div class="title title_line">买入规则</div>
        <div class="ru  box-items">
          <div class="flex-box">
            <div>起投金额</div>
            <div>{{moniesMin |format}}美元/笔</div>
          </div>
          <div class="flex-box">
            <div>投资限额</div>
            <div>{{moniesMax |format}}美元/人</div>
          </div>
          <div class="flex-box">
            <div>预约买入</div>
            <div>当日执行扣款，不可撤销</div>
          </div>
        </div>
        <div class="title title_line">成交时间</div>
        <div class="ru-time  box-items">
          <div class="flex-box">
            <div class="theme-bgcolor"></div>
            <div>每周四为固定成交计息时间，其他时间可预约买入</div>
          </div>
          <div class="flex-box">
            <div class="theme-bgcolor"></div>
            <div>每周以周三24:00为时间界限，<br/>「周三24:00前」预约买入，当周周四成交；<br/>「周三24:00后」预约买入，下周周四成交；</div>
          </div>
          <div class="flex-box">
            <div class="theme-bgcolor"></div>
            <div>成交当天开始计息，T+1收益到账。</div>
          </div>
        </div>
      </div>
      <div class="three-box" v-show="activeName2=='chu'">
        <div class="title title_line">交易流程</div>
        <div class="flex-box rule rule-chu  box-items">
          <div class="rule-left">
            <div class="rule-title">可提前转出</div>
            <div class="line-left"></div>
            <div class="rule-border"></div>
            <div class="rule-sub-title">成交</div>
          </div>
          <div class="rule-right">
             <div class="rule-title">续投/转出</div>
            <div class="line-right"></div>
            <div class="rule-border"></div>
            <div class="rule-sub-title">到期</div>
          </div>
        </div>
       
        <div class="title title_line">提前转出</div>
        <div class="ru-time  box-items">
          <div class="flex-box">
            <div class="theme-bgcolor"></div>
            <div>锁定期内可以选择提前转出，手续费为转出金额(本金+收益)的{{sellrate*100}}%。</div>
          </div>
          <div class="flex-box">
            <div class="theme-bgcolor"></div>
            <div>提前转出申请当日受理，申请当日起不再计息。</div>
          </div>
          <div class="flex-box">
            <div class="theme-bgcolor"></div>
            <div>扣除手续费后的金额将在下一个交易日转出至现金余额，可在“我的”页面中查看。</div>
          </div>
        </div>
        <div class="title title_line">到期转出/续投</div>
        <div class="ru-time  box-items">
          <div class="flex-box">
            <div class="theme-bgcolor"></div>
            <div>用户可选择开启到期「自动转入下一期」，<br/>若开启，则到期后，本金及收益继续封闭至下一周期，收益不间断；<br/>若关闭，则到期后，本金及收益将在下一个交易日自动转出至现金余额，可在“我的”页面中查看。</div>
          </div>
          <div class="flex-box">
            <div class="theme-bgcolor"></div>
            <div>到期日前一天早9点前可随时更改到期转出方式，早9点后不可更改。</div>
          </div>
          <div class="flex-box">
            <div class="theme-bgcolor"></div>
            <div>到期转出及续投均不收取任何手续费。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return {
      month:this.$route.query.periods||3,
      activeName2:"ru",
      days:0,
      moniesMin:0,
      moniesMax:0,
      sellrate:0
    }
  },
  created(){
    this.getStages()
  },
  methods:{
    getStages(){
      this.$store.dispatch('stages/getIncome',{params:{product:'akulaku',term:this.month}}).then(res => {
        if(res.term){
          this.days=res.term.days
          this.moniesMin=res.term.doorsill
          this.moniesMax=res.term.max
          this.sellrate =res.term.sellrate
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>

.tab-box2{
  background: #fff;
   ::v-deep{
    .el-tabs{
      line-height:40px;
      margin-bottom:2.5%;
      .el-tabs__header{
        margin:0px;
      }
      .el-tabs__nav{
        width: 99%;
        margin-left:.5%
      }
      .el-tabs__item{
        width: 50%;
        // font-size:1rem;
        text-align: center;
      }
      .el-tabs__active-bar{
        // width: 10% !important;
        // height: 5px;
        // border-radius: 3px;
        margin-left:22%
      }
    }
    
   }

}
.el-tabs__nav-wrap::after{
  border:none
}
.three-box{
  text-align: center;
  .flex-box{
    justify-content: center;
  }
  .box-num {
    font-size: 3rem;
    margin:10px;
    .percent{
      font-size: 1.8rem;
    }
  }
}

.rule{
  // width: 90%;
  padding: 5% 5% 4% 5%;
  background: #fff;
  .rule-title{
    line-height: 2;
    font-size: .9rem;
  }
  .rule-border{
    margin-top:4px;
    height: 8px;
    width: 30px;
    border-bottom:2px solid #d9d9d9;
    border-left:2px solid #d9d9d9;
  }
  .rule-sub-title{
    font-size: .8rem;
    line-height:1.2rem;
    text-align: left;
  }
  .rule-left{
    width: 24%;
    .line-left{
      width: 100%;
      height: 8px;
      border-radius: 4px 0px 0px 4px;
      background: -webkit-linear-gradient(left,#acacac,#e0e0e0);
      background: -o-linear-gradient(right,#acacac,#e0e0e0);
      background: -moz-linear-gradient(right,#acacac,#e0e0e0);
      background: linear-gradient(to right,#acacac,#e0e0e0);
    }
  }
  .rule-middle{
    width: 50%;
    margin:0px 2%;
    .line-middle{
      width: 100%;
      height: 8px;
      background: -webkit-linear-gradient(left,#f2545c,#ff7481);
      background: -o-linear-gradient(right,#f2545c,#ff7481);
      background: -moz-linear-gradient(right,#f2545c,#ff7481);
      background: linear-gradient(to right,#f2545c,#ff7481);
    }
  }
  .rule-right{
    width: 24%;
    .line-right{
      width: 100%;
      height: 8px;
      border-radius: 0px 4px 4px 0px;
      background: -webkit-linear-gradient(left,#d79877,#dbbfb5);
      background: -o-linear-gradient(right,#d79877,#dbbfb5);
      background: -moz-linear-gradient(right,#d79877,#dbbfb5);
      background: linear-gradient(to right,#d79877,#dbbfb5);
    }
  }
}
.rule-chu{
  .rule-left{
    width: 74%;
    margin:0px 2%;
    .line-left{
      width: 100%;
      height: 8px;
      border-radius: 4px 0px 0px 4px;
      background: -webkit-linear-gradient(left,#f2545c,#ff7481);
      background: -o-linear-gradient(right,#f2545c,#ff7481);
      background: -moz-linear-gradient(right,#f2545c,#ff7481);
      background: linear-gradient(to right,#f2545c,#ff7481);
    }
  }
}
.buy{
  background: #fff;
  // margin-top:2.5%
}
.ru{
  background: #fff;
  padding:2% 0%;
  .flex-box{
    padding:2% 2.5%;
    font-size: .9rem;
    text-align: left;
    justify-content: flex-start;
    width: 95%;
    >:first-child{
      width: 120px;
      flex-shrink: 0;
      color: #999;
      text-align: center;
    }
  }
}
.ru-time{
  background: #fff;
  padding:2% 0%;
  .flex-box{
    padding:2% 2.5%;
    font-size: .9rem;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    width: 95%;
    >:first-child{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      flex-shrink: 0;
      color: #999;
      text-align: center;
      margin-top:5px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}
</style>