var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", { attrs: { title: "交易规则", back: true } }),
      _c("div", { staticClass: "buy" }, [
        _c(
          "div",
          { staticClass: "tab-box2" },
          [
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.activeName2,
                  callback: function ($$v) {
                    _vm.activeName2 = $$v
                  },
                  expression: "activeName2",
                },
              },
              [
                _c("el-tab-pane", { attrs: { label: "买入规则", name: "ru" } }),
                _c("el-tab-pane", {
                  attrs: { label: "转出规则", name: "chu" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "tab-content-box" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName2 == "ru",
                expression: "activeName2=='ru'",
              },
            ],
            staticClass: "three-box",
          },
          [
            _c("div", { staticClass: "title title_line" }, [
              _vm._v("交易规则"),
            ]),
            _c("div", { staticClass: "flex-box rule box-items" }, [
              _vm._m(0),
              _c("div", { staticClass: "rule-middle" }, [
                _c("div", { staticClass: "rule-title" }, [
                  _vm._v("亚洲分期-" + _vm._s(_vm.month) + "月"),
                ]),
                _c("div", { staticClass: "line-middle" }),
                _c("div", { staticClass: "rule-border" }),
                _c("div", { staticClass: "rule-sub-title" }, [
                  _vm._v(_vm._s(_vm.interestDay())),
                ]),
                _c("div", { staticClass: "rule-sub-title" }, [
                  _vm._v("成交计息"),
                ]),
              ]),
              _c("div", { staticClass: "rule-right" }, [
                _c("div", { staticClass: "rule-title" }, [_vm._v("续投/转出")]),
                _c("div", { staticClass: "line-right" }),
                _c("div", { staticClass: "rule-border" }),
                _c("div", { staticClass: "rule-sub-title" }, [
                  _vm._v(_vm._s(_vm.addDays(_vm.days))),
                ]),
                _c("div", { staticClass: "rule-sub-title" }, [_vm._v("到期")]),
              ]),
            ]),
            _c("div", { staticClass: "title title_line" }, [
              _vm._v("买入规则"),
            ]),
            _c("div", { staticClass: "ru box-items" }, [
              _c("div", { staticClass: "flex-box" }, [
                _c("div", [_vm._v("起投金额")]),
                _c("div", [
                  _vm._v(_vm._s(_vm._f("format")(_vm.moniesMin)) + "美元/笔"),
                ]),
              ]),
              _c("div", { staticClass: "flex-box" }, [
                _c("div", [_vm._v("投资限额")]),
                _c("div", [
                  _vm._v(_vm._s(_vm._f("format")(_vm.moniesMax)) + "美元/人"),
                ]),
              ]),
              _vm._m(1),
            ]),
            _c("div", { staticClass: "title title_line" }, [
              _vm._v("成交时间"),
            ]),
            _vm._m(2),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName2 == "chu",
                expression: "activeName2=='chu'",
              },
            ],
            staticClass: "three-box",
          },
          [
            _c("div", { staticClass: "title title_line" }, [
              _vm._v("交易流程"),
            ]),
            _vm._m(3),
            _c("div", { staticClass: "title title_line" }, [
              _vm._v("提前转出"),
            ]),
            _c("div", { staticClass: "ru-time box-items" }, [
              _c("div", { staticClass: "flex-box" }, [
                _c("div", { staticClass: "theme-bgcolor" }),
                _c("div", [
                  _vm._v(
                    "锁定期内可以选择提前转出，手续费为转出金额(本金+收益)的" +
                      _vm._s(_vm.sellrate * 100) +
                      "%。"
                  ),
                ]),
              ]),
              _vm._m(4),
              _vm._m(5),
            ]),
            _c("div", { staticClass: "title title_line" }, [
              _vm._v("到期转出/续投"),
            ]),
            _vm._m(6),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rule-left" }, [
      _c("div", { staticClass: "rule-title" }, [_vm._v("预约")]),
      _c("div", { staticClass: "line-left" }),
      _c("div", { staticClass: "rule-border" }),
      _c("div", { staticClass: "rule-sub-title" }, [_vm._v("今日")]),
      _c("div", { staticClass: "rule-sub-title" }, [_vm._v("预约买入")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", [_vm._v("预约买入")]),
      _c("div", [_vm._v("当日执行扣款，不可撤销")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ru-time box-items" }, [
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-bgcolor" }),
        _c("div", [_vm._v("每周四为固定成交计息时间，其他时间可预约买入")]),
      ]),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-bgcolor" }),
        _c("div", [
          _vm._v("每周以周三24:00为时间界限，"),
          _c("br"),
          _vm._v("「周三24:00前」预约买入，当周周四成交；"),
          _c("br"),
          _vm._v("「周三24:00后」预约买入，下周周四成交；"),
        ]),
      ]),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-bgcolor" }),
        _c("div", [_vm._v("成交当天开始计息，T+1收益到账。")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box rule rule-chu box-items" }, [
      _c("div", { staticClass: "rule-left" }, [
        _c("div", { staticClass: "rule-title" }, [_vm._v("可提前转出")]),
        _c("div", { staticClass: "line-left" }),
        _c("div", { staticClass: "rule-border" }),
        _c("div", { staticClass: "rule-sub-title" }, [_vm._v("成交")]),
      ]),
      _c("div", { staticClass: "rule-right" }, [
        _c("div", { staticClass: "rule-title" }, [_vm._v("续投/转出")]),
        _c("div", { staticClass: "line-right" }),
        _c("div", { staticClass: "rule-border" }),
        _c("div", { staticClass: "rule-sub-title" }, [_vm._v("到期")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", { staticClass: "theme-bgcolor" }),
      _c("div", [_vm._v("提前转出申请当日受理，申请当日起不再计息。")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box" }, [
      _c("div", { staticClass: "theme-bgcolor" }),
      _c("div", [
        _vm._v(
          "扣除手续费后的金额将在下一个交易日转出至现金余额，可在“我的”页面中查看。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ru-time box-items" }, [
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-bgcolor" }),
        _c("div", [
          _vm._v("用户可选择开启到期「自动转入下一期」，"),
          _c("br"),
          _vm._v(
            "若开启，则到期后，本金及收益继续封闭至下一周期，收益不间断；"
          ),
          _c("br"),
          _vm._v(
            "若关闭，则到期后，本金及收益将在下一个交易日自动转出至现金余额，可在“我的”页面中查看。"
          ),
        ]),
      ]),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-bgcolor" }),
        _c("div", [
          _vm._v(
            "到期日前一天早9点前可随时更改到期转出方式，早9点后不可更改。"
          ),
        ]),
      ]),
      _c("div", { staticClass: "flex-box" }, [
        _c("div", { staticClass: "theme-bgcolor" }),
        _c("div", [_vm._v("到期转出及续投均不收取任何手续费。")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }